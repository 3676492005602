import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import ImageModal from "./ImageModal";

const ProjectDetail = ({ project, totalWorks }) => {
  const [modalImage, setModalImage] = useState(null);
  const handleImageClick = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const cutStringByWords = (text, startWord, endWord) => {
    const words = text.split(" "); // Split the string by spaces to get an array of words
    const slicedWords = words.slice(startWord, endWord); // Get words from 'startWord' to 'endWord' &&
    return slicedWords.join(" "); // Join the words back into a string
  };

  return (
    <>
      {project?.video_link === null ? (
        <>
          <Slider images={project?.slider_images} />
        </>
      ) : (
        <>
          <div
            className="relative overflow-hidden"
            style={{ paddingTop: "56.25%" }}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src={`https://www.youtube.com/embed/${
                project?.video_link.split("v=")[1]
              }`}
              title={project.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </>
      )}

      <div className="container mx-auto">
        <div className="mb-4 border-b border-dashed border-secondary">
          <h1 className="font-sourceCode tracking-0.23 mt-4 lg:font-600 font-normal md:text-20px  xl:text-15px 2xl:text-20px leading-25  md:mb-4 text-primary">
            {project.title}
            <br />
            {project.subtitle}
          </h1>
          {/* <p className="text-gray-700">{project.subtitle}</p> */}
        </div>
        <div>
          <h1 className="my-4 tracking-0.23 font-sourceCode  text-primary lg:text-14px 2xl:text-15px">
            {project.content_title} {project.year}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row mb-8">
          <div className="w-full md:w-1/2">
            <p className="mt-2 tracking-0.23 font-sourceCode  text-secondary lg:text-14px 2xl:text-15px">
              {/* {project?.content?.substring(0, 320)} */}
              {cutStringByWords(project?.content, 0, 50)}
            </p>
          </div>

          <div className="w-full md:w-1/2 md:ml-8">
            <p className="mt-2 tracking-0.23 font-sourceCode  text-secondary lg:text-14px 2xl:text-15px">
              {/* {project?.content?.substring(60, 120)} */}
              {cutStringByWords(project?.content, 50, 100)}
            </p>
          </div>
        </div>
        <div className="my-2">
          <img
            src={`/images/${project?.cover_image}`}
            alt={`Slide ${project.title}`}
            className="w-full h-full object-cover cursor-zoom-in"
            onClick={() =>
              handleImageClick({
                src: project.cover_image,
                title: project.title,
              })
            }
          />
        </div>

        {modalImage && (
          <ImageModal
            src={modalImage.src}
            alt={modalImage.title}
            onClose={closeModal}
          />
        )}

        <div className="mb-8">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-2 mt-2">
            {project?.body_image?.map((image, index) => (
              <div
                key={index}
                className="relative overflow-hidden aspect-w-1 aspect-h-1"
              >
                <img
                  src={`/images/${image}`}
                  alt={`${project.title}-${image}-${index + 1}`}
                  className="object-cover w-full h-full cursor-zoom-in"
                  onClick={() =>
                    handleImageClick({
                      src: image,
                      title: project.title,
                    })
                  }
                />
              </div>
            ))}
          </div>

          <div className="w-full mt-8">
            <p className="mt-2 tracking-0.23 font-sourceCode  text-secondary lg:text-14px 2xl:text-15px">
              {/* {project?.content?.substring(360)} */}
              {cutStringByWords(project?.content, 100)}
            </p>
          </div>

          <div>
            <h1 className="my-4 tracking-0.23 font-sourceCode  text-primary lg:text-14px 2xl:text-15px">
              {project.content_title} {project.year}
            </h1>
          </div>
          {console.log(totalWorks)}
          <div className="flex justify-between border-t border-secondary my-8">
            <Link
              to={`/work/${project.id === 1 ? totalWorks : project.id - 1}`}
              className="text-primary font-sourceCode lg:text-14px 2xl:text-15px my-8 hover:underline"
            >
              ← Previous Work
            </Link>
            <Link
              to={`/work/${totalWorks === project.id ? 1 : project.id + 1}`}
              className="text-primary font-sourceCode lg:text-14px 2xl:text-15px my-8 hover:underline"
            >
              Next Work →
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
